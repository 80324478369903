.menu {
    width: 18%;
  
    &__lista {
      padding-left: 0.5rem;
      margin: 0;
      height: 276px;
      list-style: none;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
    }
  
    &__item {
      display: flex;
      justify-content: flex-start;
      align-items: center;
  
      &:first-child {
        a {
          font-weight: 700;
          color: #7b78e5;
          font-family: "GandhiSansRegular";
        }
      }
    }
  
    img {
      margin-right: 0.5rem;
      width: 25px;
      height: 25px;
      cursor: pointer;
    }
  
    a {
      text-decoration: none;
      font-size: 1.125rem;
      color: #d9d9d9;
      font-family: "GandhiSansRegular"
    }
  }