main {
    margin: 0 24px;
  }
  
  .principal {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 2rem;
  
    &__imagem {
      width: 88%;
      img {
        width: 100%;
        height: 288px;
      }
  
      position: relative;
  
      h1 {
        position: absolute;
        z-index: 1;
        color: #d9d9d9;
        font-size: 2.5rem;
        width: 401px;
        height: 144px;
        top: 75px;
        left: 44px;
        font-family: "GandhiSansBold";
      }

      
    }

.galeria {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-left: 17%;
  margin-top: 1rem;

}


  }

