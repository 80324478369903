@import "../../styles/variaveis.scss";

.rodape {
  background-color: $azul-rodape;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  position: fixed;
  bottom: 0;
  height: 60px;
  width: 100%;
  box-shadow: 0px -2px 30px rgba($color: #000000, $alpha: 0.5);
  box-sizing: border-box;

  &__icones {
    width: 10%;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
  }

  p {
    margin: 0;
    color: $cor-do-texto;
    font-family: $fonte-normal;
  }
}
