@import-normalize;
@import "./variaveis.scss";

body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  background-image: linear-gradient($azul-escuro, $azul-claro);
  box-sizing: border-box;
}

body::-webkit-scrollbar {
  width: 3px;
}
body::-webkit-scrollbar-track {
  background: linear-gradient($azul-escuro, $azul-claro);
}
body::-webkit-scrollbar-thumb {
  background-color: #154580;
  border-radius: 20px;
}

body::-webkit-scrollbar{
  width: 3px;

}

body::-webkit-scrollbar-thumb{
  background-color: #154580;
  border-radius: 20px;
  
}

body::-webkit-scrollbar-track{
  background: linear-gradient(#041833, #154580);
}


